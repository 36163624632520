/*
  命名规则:
  首字母小写驼峰
  e.g: userInfo
*/
export default {
  xppThemeState: JSON.parse(localStorage.getItem("xppThemeState")) || 0,
  // 用户信息
  xcanvasReadyState: false, // xcanvas WS链接状态
  inDataTransmission: false, // 是否有数据待传输
  xppUserInfo: JSON.parse(localStorage.getItem("XppUserInfo")) || {},
  userRoleInTheTeam: false, // 用户在当前团队中的角色
  xppTeamMemberAllList:
    JSON.parse(localStorage.getItem("xppTeamMemberAllList")) || [], // 团队成员列表（包含被冻结人员）
  companyOnlineList: [], // 团队当前在线人员列表
  viewMaskShow: false, // 遮罩层
  // 收到的加入团队的邀请
  joinTheTeamDialog: {
    state: false,
    info: {
      remindId: "",
      companyId: "",
      companyName: "",
      reminduser_id: "",
      reminduser_name: "",
      reminduser_headimg: ""
    }
  },
  // 弹出窗口
  dialogElementShow: {
    userCtrl: false, // 用户中心
    userCtrlSettingOpen: false, // 用户信息设置
    userPhotoChangeDialog: false, // 用户头像调整
    noticeContainer: false, // 通知详情
    searchCtrl: false, // 搜索
    messageCtrl: false, // 消息
    paperPageTitEdit: false, // 编辑页面标题
    pageBgEdit: false, // 编辑页面背景色
    pageMoreMenu: false, // 页面操作菜单
    pageSizeEditView: false, // 修改页面大小
    xppCommentShow: false, // 评论详情
    xppContactListShow: false, // 评论详情
    paperContext: false, // 右键菜单
    xppEditToolbarState: false, // 富文本编辑工具栏
    xppEditHistoryLogState: false, // 版本历史记录
    xppAllowanceCreditShow: false // 余量不足提示窗口
  },
  // 邀请用户加入项目/paper 弹窗
  dialogInvitationToJoin: {
    id: "",
    type: "",
    title: "",
    member: [],
    visible: false,
    resourceType: ""
  },
  paperOperateMode: 2, // paper的操作权限
  homeViewScale: 0, // homePaper 缩放比例
  homeViewScaleReset: 0, // homePaper 初始缩放比例
  // paper下页面原始尺寸信息
  paperViewZoomInfo: {
    width: 3300,
    height: 1856,
    bottom: 40
  },
  paperPageSizeInfo: {}, // page 计算后尺寸
  editToolbarActive: 1, // 工具栏当前激活功能id
  slideSidebarOpen: true, // 侧边栏视图
  // 绘图画笔属性
  strokeInfo: JSON.parse(localStorage.getItem("strokeInfo")) || {
    color: "#ff6565",
    weight: 10,
    opacity: "1"
  },
  // 文本工具默认属性
  textDefalutInfo: {
    width: 1300,
    height: 340,
    backgroundColor: "transparent",
    zIndex: 5
  },
  // 便签默认样式
  noteDefalutInfo: JSON.parse(localStorage.getItem("xppNoteInfoConfig")) || {
    backgroundColor: "#fff393",
    width: 650,
    height: 340,
    zIndex: 5
  },
  // paper_page 的默认数据格式
  paperPageDefaultData: {
    _id: null,
    page_id: null,
    pageTitle: "", // page 标题
    mousemoveHistory: {}, // 鼠标轨迹记录
    pageStyle: {}, // page 样式
    noteSequence: {}, //  便签
    paperSequence: {}, // paper
    svgSequence: {}, // 线条
    textSequence: {}, // 文本
    hyperlinkSequence: {}, // 超链接
    filesSequence: {}, // 文件
    imagesSequence: {} // 图片
  },
  paperGroupId: "", // paper通道id
  paperChannelId: null, // paper通道id
  paperCategoryList: [], // 当前paper类目归属
  paperOwnerInfo: {}, // paper 所有者信息
  paperViewAll: [], // 当前paper下所有内容
  paperPageInfo: {}, // 最近被操作的page
  lastEventCoordinate: {}, // 最后操作paper区域时鼠标的位置
  paperPageNodeGrid: {}, // page上节点的坐标信息集合
  paperGridPathInfo: {}, // 辅助线信息
  // 设置页面信息时缓存信息
  paperPageEditDate: {
    id: null,
    index: null,
    title: null,
    backgroundColor: null
  },
  paperActiveDom: {}, // 最近被激活的dom
  activeRemovePath: false, // svg橡皮擦 激活状态
  paperElementDropStart: false, // 开始拖动节点
  // 选中节点位置信息
  pagePositionInfo: {
    width: 0,
    top: 0,
    left: 0
  },
  paperWS: null, // IM
  dragFileEnterOff: false, // 拖拽文件时遮罩层Off
  showFilePreview: false, // 显示文件预览窗口
  previewFileInfo: {}, // 预览文件信息
  showPicturePreview: false, // 显示图片预览窗口
  triggerImgInfo: {}, // 触发预览效果的图片信息
  // 评论详情
  elementCommentInfo: {
    commentId: "",
    dom: null,
    offset: {},
    targetData: null,
    list: []
  },
  noticeListInfo: [], // 通知列表
  // 通知列表页码信息
  noticeListPageInfo: {
    unReadCount: 0, // 未读数量
    pageNum: 1, // 当前页码
    pageSize: 20, // 每页数据量
    total: 0, // 总数据量
    currentTotal: 0 // 当前加载数量
  },
  paperPeopleOnlineList: [], // 当前paper在线人员列表
  editToolbarInnerHtml: `<span class="ql-formats"><select class="ql-font"><option selected></option><option value="serif"></option><option value="monospace"></option><option value="yahei"></option><option value="simhei"></option><option value="simsun"></option><option value="kaiti"></option><option value="siliguri"></option><option value="libre"></option><option value="opensans"></option><option value="robotomono"></option><option value="robotoslab"></option></select><select class="ql-size xpp-size-select"><option value="22px"></option><option value="26px"></option><option value="28px"></option><option value="30px"></option><option value="42px"selected></option><option value="52px"></option><option value="58px"></option><option value="72px"></option><option value="92px"></option><option value="112px"></option></select></span><span class="ql-formats"><select class="ql-color"></select><select class="ql-background"></select></span><span class="ql-formats"><select class="ql-align"></select></span><span class="ql-formats"><button class="ql-bold"></button><button class="ql-italic"></button><button class="ql-underline"></button><button class="ql-strike"></button></span><span class="ql-formats"><button class="ql-list"value="check"></button><button class="ql-list"value="ordered"></button><button class="ql-list"value="bullet"></button></span>`,
  editHistoryLogList: [], // 版本历史记录
  xppContactDataInfo: {}, // 通讯录位置及光标信息
  xppContactNoticeInfo: {}, // 选定 本次编辑中待通知人员名单
  xppQuillEditContainer: null, // 当前激活的编辑器
  // 余量不足提示信息
  xppAllowanceCredit: {
    tit: "",
    content: "",
    href: "javascript:;"
  },
  // 余量信息
  xppAllowanceInfo: {},
  // 项目成员管理
  xppProjectMemberInfo: {
    status: false,
    userRoleId: false,
    observerAuthority: false,
    title: "",
    projetcId: "",
    memberlist: [],
    partnerlist: []
  },
  // 项目成员列表
  xppResourceMemberList: {
    memberlist: [],
    partnerlist: []
  },
  // 分享paper弹窗
  xppPaperShareDialogInfo: {
    state: false,
    loading: false,
    name: "",
    id: "",
    share: ""
  },
  // 设置弹窗2
  opensetUp: {
    state: false,
    type: 1,
    name: "",
    id: ""
  },
  // 操作记录
  xppOperationRecord: [
    [], // 可撤销
    [] // 可恢复
  ],
  // 控制展示状态
  rowOrCell: JSON.parse(localStorage.getItem("rowOrCell")) || "block",
  // 幻灯片放映准备状态
  xppSlidePlayerReady: 1,
  // paper受密码保护状态
  xppPaperPassword: {
    show: false, // 窗口是否显示
    ispwd: false, // 是否已设置密码
    state: 1, // 窗口状态
    founderInfo: {} // 密码创建人信息
  },
  xppCollectionList: [],
  //退出弹窗
  outProject: {
    type: 1, //1退出 2删除
    show: false, //窗口是否显示
    project: {}, //要操作的项目
    index: "",
    back: null
  },
  contextMenuType: {
    type: 1 // 1 项目 2 paper
  },
  refresh: true,
  globalUploadStatus: false,
  xppUserProjectList: [], // 当前用户所有paper及project
  xppLatelyPaper: [], // 当前用户最近paper
  quilluserConfig: JSON.parse(localStorage.getItem("quilluserConfig")) || {}, // quill 用户配置
  // @时联系人过滤目标字段
  xppContactsFilterInfo: {
    selection: null, // 起始位置
    text: "" // 已输入字段
  },
  // 联系人列表为空或异常时信息
  XppContactListError: {
    msg: ""
  },
  // paper导航拖动配置项
  paperDragOptions: {
    animation: 200,
    group: "description",
    disabled: false,
    ghostClass: "xpp-drop-ghost"
  }
};
