export default {
  xppThemeState: state => state.xppThemeState,
  xcanvasReadyState: state => state.xcanvasReadyState,
  xppUserInfo: state => state.xppUserInfo,
  xppCompanyId: state => state.xppUserInfo.companyId,
  viewMaskShow: state => state.viewMaskShow,
  joinTheTeamDialog: state => state.joinTheTeamDialog,
  xppTeamMemberAllList: state => state.xppTeamMemberAllList,
  xppTeamMemberList: state =>
    state.xppTeamMemberAllList.filter(item => item.status === 1),
  companyOnlineList: state => state.companyOnlineList,
  userRoleInTheTeam: state => state.userRoleInTheTeam,
  dialogInvitationToJoin: state => state.dialogInvitationToJoin,
  dialogElementShow: state => state.dialogElementShow,
  paperOperateMode: state => state.paperOperateMode,
  homeViewScale: state => state.homeViewScale,
  homeViewScaleReset: state => state.homeViewScaleReset,
  paperViewZoomInfo: state => state.paperViewZoomInfo,
  paperPageSizeInfo: state => state.paperPageSizeInfo,
  editToolbarActive: state => state.editToolbarActive,
  slideSidebarOpen: state => state.slideSidebarOpen,
  strokeInfo: state => state.strokeInfo,
  textDefalutInfo: state => state.textDefalutInfo,
  noteDefalutInfo: state => state.noteDefalutInfo,
  paperPageDefaultData: state => JSON.stringify(state.paperPageDefaultData),
  paperGroupId: state => state.paperGroupId,
  paperPageInfo: state => state.paperPageInfo,
  paperPageNodeGrid: state => state.paperPageNodeGrid,
  paperGridPathInfo: state => state.paperGridPathInfo,
  paperPageEditDate: state => state.paperPageEditDate,
  paperCategoryList: state => state.paperCategoryList,
  paperViewAll: state => state.paperViewAll,
  paperActiveDom: state => state.paperActiveDom,
  activeRemovePath: state => state.activeRemovePath,
  paperElementDropStart: state => state.paperElementDropStart,
  pagePositionInfo: state => state.pagePositionInfo,
  dragFileEnterOff: state => state.dragFileEnterOff,
  lastEventCoordinate: state => state.lastEventCoordinate,
  showFilePreview: state => state.showFilePreview,
  previewFileInfo: state => state.previewFileInfo,
  showPicturePreview: state => state.showPicturePreview,
  triggerImgInfo: state => state.triggerImgInfo,
  elementCommentInfo: state => state.elementCommentInfo,
  noticeListInfo: state => state.noticeListInfo,
  noticeListPageInfo: state => state.noticeListPageInfo,
  paperPeopleOnlineList: state => state.paperPeopleOnlineList,
  editHistoryLogList: state => state.editHistoryLogList,
  xppContactDataInfo: state => state.xppContactDataInfo,
  xppContactNoticeInfo: state => state.xppContactNoticeInfo,
  xppQuillEditContainer: state => state.xppQuillEditContainer,
  xppAllowanceCredit: state => state.xppAllowanceCredit,
  xppAllowanceInfo: state => state.xppAllowanceInfo,
  xppProjectMemberInfo: state => {
    switch (state.xppProjectMemberInfo.userRoleId) {
      case 1:
      case 3:
      case 4:
      case 6:
      case 8:
      case 10:
        state.xppProjectMemberInfo.observerAuthority = true;
        break;
      default:
        state.xppProjectMemberInfo.observerAuthority = false;
        break;
    }
    return Object.assign(
      state.xppProjectMemberInfo,
      state.xppResourceMemberList
    );
  },
  xppResourceMemberList: state => state.xppResourceMemberList,
  xppPaperShareDialogInfo: state => state.xppPaperShareDialogInfo,
  opensetUp: state => state.opensetUp,
  paperOwnerInfo: state => state.paperOwnerInfo,
  xppOperationRecord: state => state.xppOperationRecord,
  xppSlidePlayerReady: state => state.xppSlidePlayerReady,
  xppPaperPassword: state => state.xppPaperPassword,
  rowOrCell: state => state.rowOrCell,
  outProject: state => state.outProject,
  xppCollectionList: state => state.xppCollectionList,
  contextMenuType: state => state.contextMenuType,
  refresh: state => state.refresh,
  globalUploadStatus: state => state.globalUploadStatus,
  xppUserProjectList: state => state.xppUserProjectList,
  xppLatelyPaper: state => state.xppLatelyPaper,
  quilluserConfig: state => state.quilluserConfig,
  xppContactsFilterInfo: state => state.xppContactsFilterInfo,
  XppContactListError: state => state.XppContactListError,
  paperDragOptions: state => state.paperDragOptions
};
