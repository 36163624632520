<template>
  <div
    id="xpaper"
    :class="xppThemeBody"
    @drop="drop"
    @dragover="drop"
    @click="clearMenu"
  >
    <router-view />
    <template v-if="!printing">
      <Dialog />
      <XppSearchDialog />
      <div
        id="viewMaskTop"
        v-show="viewMaskShow"
        @click="closeDialog"
        @contextmenu="maskContextmenu"
      ></div>
      <InvitationToJoin />
    </template>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  components: {
    Dialog: () => import("@components/layout/Dialog"),
    XppSearchDialog: () => import("@components/XppSearchDialog"),
    InvitationToJoin: () => import("@components/InvitationToJoin")
  },
  computed: {
    xppThemeBody() {
      return {
        "xpp-theme2-body": this.xppThemeState === 1
      };
    },
    ...mapGetters(["viewMaskShow", "xppThemeState", "paperGroupId"])
  },
  data() {
    return {
      printing: false
    };
  },
  created() {
    // 移除loading
    let loading = document.getElementById("loader-wrapper");
    loading && loading.parentNode.removeChild(loading);
    if (location.pathname.indexOf("xpp_print") > -1) {
      this.printing = true;
      return;
    }
  },
  methods: {
    clearMenu() {
      if (window.openmenu && window.openmenu.type) {
        window.openmenu.type = false;
      }
    },
    drop(event) {
      event.preventDefault();
      this.$UpdataVuexState({ key: "dragFileEnterOff", data: false });
    },
    closeDialog() {
      this.$SetPaperActiveDom(); // 移除节点激活状态
      this.$UpdataVuexState({ key: "viewMaskShow", data: false });
      this.$DiaLoginFoUpDate();
    },
    maskContextmenu(event) {
      event.preventDefault();
      return false;
    },
    ...mapMutations([
      "$UpdataVuexState",
      "$DiaLoginFoUpDate",
      "$SetPaperActiveDom",
      "$UpdataVuexState"
    ])
  }
};
</script>
<style lang="scss">
@import "assets/scss/base.scss";

#xpaper {
  cursor: url(/img/mouse_pointer.png), url(/img/cursor_default.cur), default;
}

#viewMaskTop,
.view-mask-modal {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
}

#viewMaskTop {
  z-index: $view-mask-occlusion;
}

.view-mask-modal {
  z-index: $view-mask-modal;
}
</style>
