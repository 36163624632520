function timeago(dateTimeStamp) {
  let result = ""; //发布时间
  let minute = 1000 * 60; //把分，时，天，周，半个月，一个月用毫秒表示
  let hour = minute * 60;
  let day = hour * 24;
  let week = day * 7;
  let month = day * 30;
  let now = new Date().getTime(); //获取当前时间毫秒
  let diffValue = now - dateTimeStamp; //时间差
  if (diffValue < 0) {
    return false;
  }
  var minC = diffValue / minute; //计算时间差的分，时，天，周，月
  var hourC = diffValue / hour;
  var dayC = diffValue / day;
  var weekC = diffValue / week;
  var monthC = diffValue / month;
  if (parseInt(monthC) >= 1 && parseInt(monthC) <= 3) {
    result = " " + parseInt(monthC) + "月前";
  } else if (parseInt(weekC) >= 1 && parseInt(weekC) <= 3) {
    result = " " + parseInt(weekC) + "周前";
  } else if (parseInt(dayC) >= 1 && parseInt(dayC) <= 6) {
    result = " " + parseInt(dayC) + "天前";
  } else if (parseInt(hourC) >= 1 && parseInt(hourC) <= 23) {
    result = " " + parseInt(hourC) + "小时前";
  } else if (parseInt(minC) >= 1 && parseInt(minC) <= 59) {
    result = " " + parseInt(minC) + "分钟前";
  } else if (diffValue >= 0 && diffValue <= minute) {
    result = "刚刚";
  }
  return result;
}
export default timeago;
