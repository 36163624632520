// 节点祖先查找
function parentLocation(target, classNameArr) {
  classNameArr = [].concat(classNameArr);
  let elarr = classNameArr.map(item => {
    if (target.classList.contains(item)) {
      return target;
    } else {
      return target.closest(item);
    }
  });
  return classNameArr.length > 1 ? elarr : elarr.shift();
}
// 生成ID
function creatElementId(data = "paper") {
  let hash = Math.random()
    .toString(32)
    .replace("0.", "");
  return `${data}${hash}${new Date().getTime().toString(32)}`;
}

// 评论窗口定位
function commentDialogOffset({
  target,
  parentEl,
  commentEl,
  pageX,
  pageY,
  homeViewScale
}) {
  let top = parentEl.offsetTop + target.offsetTop + 120; // page_head h:120
  let left = target.offsetLeft;
  let offset,
    ofs = target.getBoundingClientRect();
  if (target.dataset.type === "imagesSequence") {
    let oftt = (pageY - ofs.top) / homeViewScale;
    let ofll = (pageX - ofs.left) / homeViewScale;
    offset = {
      oftt,
      ofll,
      top: top + 60 + (commentEl ? commentEl.offsetTop : oftt),
      left: left + 60 + (commentEl ? commentEl.offsetLeft : ofll)
    };
  } else {
    offset = {
      top: top + target.clientHeight + 5,
      left: left + target.clientWidth + 5
    };
  }
  return offset;
}

// 请求参数格式化
function formParamsFormat(params) {
  let data = new FormData();
  for (let i in params) {
    data.append(i, params[i]);
  }
  return data;
}
// 获取节点在先祖中的位置
function ElementFindIdx(target, parent) {
  if (!parent.contains(target)) return false;
  let parentArr = [];
  let idxArr = [];
  let i = true;
  let targetItem = target;
  while (i) {
    let item = targetItem.parentElement;
    parentArr.unshift(item);
    targetItem = item;
    if (item === parent) {
      i = false;
    }
  }
  for (let i = 0; i < parentArr.length; i++) {
    let item = parentArr[i];
    let targetItem = parentArr[i + 1];
    if (!targetItem) targetItem = target;
    [...item.children].map((dd, idx) => {
      if (dd === targetItem) {
        idxArr.push(idx);
      }
    });
  }
  // 父级 -> 子级
  return idxArr;
}

// 通过节点位置找出节点
function ElementIdxGetChild(box, idx) {
  let target = box.children[idx.shift()];
  if (idx.length) return ElementIdxGetChild(target, idx);
  else return target;
}

// 根据节点ID，在paper数据中查询对应数据
function PaperGetElementById(id, list) {
  for (let i in list) {
    let item = list[i];
    let keys = Object.keys(item).filter(item => item.indexOf("Sequence") > 0);
    for (let i in keys) {
      let sequence = item[keys[i]];
      if (sequence[id]) return sequence[id];
    }
  }
  return false;
}

export {
  parentLocation,
  creatElementId,
  commentDialogOffset,
  formParamsFormat,
  ElementFindIdx,
  ElementIdxGetChild,
  PaperGetElementById
};
