import Vue from "vue";
import router from "./router/index";
import store from "./store/index";
import "./registerServiceWorker";
import "./plugins/axios";
import "./plugins/filter";
import App from "./App.vue";
import { rules, sortFilter } from "./method/jok";
import contentmenu from "@components/view/contentmenu/index.js";
import VueLazyload from "vue-lazyload";

Vue.use(contentmenu);

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: "../img/img_error.svg",
  loading: "../img/img_loading.svg",
  attempt: 1,
  adapter: {
    // listender, InitOption
    loaded(listender) {
      listender.el.style.setProperty("object-fit", "fill", "important");
    }
  },
  listenEvents: ["scroll", "resize"]
});

// Vue config
Vue.config.productionTip = false;
Vue.config.devtools = process.env.VUE_APP_DEVTOOLS;
// handle error
// Vue.config.errorHandler = function(err) {
//   // `info` 是 Vue 特定的错误信息，比如错误所在的生命周期钩子
//   axios.post("/index/extend/saveError", {
//     errMsg: {
//       type: "error",
//       message: err.message,
//       stack: err.stack
//     },
//     time: new Date().toLocaleString(),
//     userId: store.getters.xppUserInfo.id,
//     groupId: store.getters.paperGroupId || location.pathname
//   });
// };
// // handle warn
// Vue.config.warnHandler = function(msg, vm, trace) {
//   axios.post("/index/extend/saveError", {
//     errMsg: {
//       type: "warn",
//       message: msg,
//       info: trace
//     },
//     time: new Date().toLocaleString(),
//     userId: store.getters.xppUserInfo.id,
//     groupId: store.getters.paperGroupId
//   });
// };

//过滤方法
Vue.prototype.$jok = { rules, sortFilter };
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#xpaper");
