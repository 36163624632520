import Vue from "vue";
import Router from "vue-router";
const Home = () =>
  import(/* webpackChunkName: "HomeIndex" */ "../views/home/Home.vue");

const HomeIndex = () =>
  import(/* webpackChunkName: "HomeIndex" */ "../views/home/HomeIndex.vue");

const HomeProject = () =>
  import(/* webpackChunkName: "HomeIndex" */ "../views/home/HomeProject.vue");

const HomeFavorites = () =>
  import(/* webpackChunkName: "HomeIndex" */ "../views/home/HomeFavorites.vue");

const HomeActive = () =>
  import(/* webpackChunkName: "HomeIndex" */ "../views/home/HomeActive.vue");

const ReceivedPaper = () =>
  import(/* webpackChunkName: "HomeIndex" */ "../views/home/ReceivedPaper.vue");

const RecentPaper = () =>
  import(/* webpackChunkName: "HomeIndex" */ "../views/home/RecentPaper.vue");

const HomeProjectPaper = () =>
  import(
    /* webpackChunkName: "HomeIndex" */ "../views/home/HomeProjectPaper.vue"
  );

const Member = () =>
  import(/* webpackChunkName: "HomeIndex" */ "../views/home/Member.vue");

const RecycleBin = () =>
  import(/* webpackChunkName: "HomeIndex" */ "../views/home/RecycleBin.vue");

const Product = () =>
  import(/* webpackChunkName: "Product" */ "../views/product/Index.vue");
const ProductIndex = () =>
  import(/* webpackChunkName: "Product" */ "../views/product/ProductIndex.vue");
const WorkFromHome = () =>
  import(/* webpackChunkName: "Product" */ "../views/product/WorkFromHome.vue");
const Scenarios = () =>
  import(/* webpackChunkName: "Product" */ "../views/product/Scenarios.vue");

const Paper = () =>
  import(/* webpackChunkName: "Paper" */ "../views/paper/Paper.vue");

const Share = () =>
  import(/* webpackChunkName: "Share" */ "../views/paper/Share.vue");

const Passport = () =>
  import(/* webpackChunkName: "Passport" */ "../views/passport/passport.vue");

const Pay = () => import(/* webpackChunkName: "Pay" */ "../views/pay/Pay.vue");

const Capacity = () =>
  import(/* webpackChunkName: "Pay" */ "../views/pay/Capacity.vue");

const Payment = () =>
  import(/* webpackChunkName: "Pay" */ "../views/pay/Payment.vue");

const Vippay = () =>
  import(/* webpackChunkName: "Pay" */ "../views/pay/Vippay.vue");

const Tjoin = () =>
  import(/* webpackChunkName: "Tjoin" */ "../views/tjoin/index.vue");

const ErrorView = () =>
  import(/* webpackChunkName: "ErrorView" */ "../views/ErrorView/Index.vue");

const ErrorView404 = () =>
  import(/* webpackChunkName: "ErrorView" */ "../views/ErrorView/404.vue");

const ErrLog = () =>
  import(/* webpackChunkName: "ErrorView" */ "../views/ErrorView/Errlog.vue");

Vue.use(Router);

let router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/paper/:id",
      name: "Paper",
      component: Paper,
      ws: false
    },
    {
      path: "/s/:info",
      name: "Share",
      component: Share,
      meta: {
        doorGod: "visitor",
        ws: false
      }
    },
    {
      path: "/ems",
      name: "ShareEmail",
      component: Share,
      meta: {
        doorGod: "visitor",
        ws: false
      }
    },
    {
      path: "/t/join/:info",
      name: "Tjoin",
      component: Tjoin
    },
    {
      path: "/error_view",
      name: "ErrorView",
      component: ErrorView,
      children: [
        {
          path: "errlog",
          name: "ErrLog",
          component: ErrLog
        },
        { path: "*", name: "ErrorView404", component: ErrorView404 }
      ]
    },
    {
      path: "/passport",
      alias: "/passport/*",
      name: "Passport",
      component: Passport,
      meta: {
        doorGod: "visitor"
      }
    },
    {
      path: "/pay",
      component: Pay,
      children: [
        {
          path: "capacity",
          name: "Capacity",
          component: Capacity
        },
        {
          path: "payment",
          name: "Payment",
          component: Payment
        },
        {
          path: "vippay",
          name: "Vippay",
          component: Vippay
        }
      ]
    },
    {
      path: "/",
      name: "Home",
      component: () => {
        let token = localStorage.getItem("token");
        if (!token) {
          return Product();
        }
        return Home();
      },
      children: [
        {
          path: "work-from-home",
          name: "WorkFromHome",
          component: WorkFromHome,
          meta: {
            doorGod: "users"
          }
        },
        {
          path: "scenarios",
          name: "Scenarios",
          component: Scenarios,
          meta: {
            doorGod: "users"
          }
        },
        {
          path: "favorites",
          name: "HomeFavorites",
          component: HomeFavorites,
          meta: {
            doorGod: "welcome"
          }
        },
        {
          path: "active",
          name: "HomeActive",
          component: HomeActive,
          meta: {
            doorGod: "welcome"
          }
        },
        {
          path: "received",
          name: "ReceivedPaper",
          component: ReceivedPaper,
          meta: {
            doorGod: "welcome"
          }
        },
        {
          path: "recent",
          name: "RecentPaper",
          component: RecentPaper,
          meta: {
            doorGod: "welcome"
          }
        },
        {
          path: "member",
          name: "Member",
          component: Member,
          meta: {
            doorGod: "welcome"
          }
        },
        {
          path: "recyclebin",
          name: "RecycleBin",
          component: RecycleBin,
          meta: {
            doorGod: "welcome"
          }
        },
        {
          path: "project",
          name: "HomeIndex",
          component: HomeIndex,
          children: [
            {
              path: "",
              name: "HomeProject",
              component: HomeProject,
              meta: {
                doorGod: "welcome"
              }
            },
            {
              path: ":id",
              name: "HomeProjectPaper",
              component: HomeProjectPaper,
              meta: {
                doorGod: "welcome"
              }
            }
          ]
        },
        {
          path: "",
          name: "ProductIndex",
          component: ProductIndex,
          meta: {
            doorGod: "users"
          }
        }
      ]
    },
    {
      path: "*",
      name: "ErrorView404",
      component: ErrorView404
    }
  ]
});

function toLogin(bkPath, next) {
  localStorage.removeItem("token");
  sessionStorage.setItem("returnUrl", bkPath);
  next({
    name: "Passport",
    replace: true
  });
}

function welcome(token, next) {
  if (!token) {
    return next({ name: "ProductIndex" });
  }
  next();
}

function users(token, next) {
  if (token) {
    return next({ name: "HomeProject" });
  }
  next();
}

/**
 * doorGod?: number
 * visitor 访客可访问
 * welcome 首页鉴权 未登录重定向至欢迎页
 * 其他 判断有无token 没有就跳转至登录页
 */
router.beforeEach((to, from, next) => {
  let token = localStorage.getItem("token");

  if (to.meta) {
    switch (to.meta.doorGod) {
      case "visitor":
        return next();
      case "welcome":
        return welcome(token, next);
      case "users":
        return users(token, next);
    }
  }

  if (!token) return toLogin(to.path, next);
  next();
});

export default router;
