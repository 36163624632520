import dateFilter from "./date";

export const rules = (array = [], id) => {
  let newArray = [...array]; // 新数组
  newArray.map(res => {
    res.colour = res.colour ? res.colour : "#f7bd02";
    if (res.membersList) {
      var index = res.membersList.findIndex(rej => rej.userId == id);
    } else {
      index = 999;
    }
    //包含paper
    if (index == 999) {
      res.owner = res.member;
      try {
        res.contextmenu = res.member.id == id ? 4 : false;
      } catch (err) {
        console.error(err);
        console.log(res);
      }
      return;
    }
    if (index == -1) {
      res.owner = {
        nickName: " ",
        ...res.membersList.filter(res => res.roleId == 4)[0]
      };
      res.contextmenu = false;
    } else {
      //找到了index
      res.contextmenu = res.membersList[index].roleId;
      res.owner = res.membersList[index];
    }
  });

  return newArray;
};

export const sortFilter = (array = [], obj = { class: 1, sort: 1 }) => {
  //class 1 全部 2 未加入 3已加入 4 项目 5 paper
  //sort
  //最后修改时间  1 正 -1 反 updateDate
  //名称从大到小  2 正 -2 反 name
  //所有者       3 正 -3 反 owner
  //创建日期     4 正 -4 反 createDate
  //标签         5 正 -5 反 colour
  obj.class = Number(obj.class);
  obj.sort = Number(obj.sort);
  let log = console.log;
  //过滤 类型
  let newArray = []; //此数组为最终返回数组

  //循环数组
  let classArray = array.filter(res => {
    //类型分为 1 2 3 4 5 为了让常俊超舒服 这里决定使用 switch
    switch (obj.class) {
      case 1:
        return true;
      case 2:
        return res.contextmenu;
      case 3:
        return !res.contextmenu;
      case 4:
        return res.soruceType == 1;
      case 5:
        return res.soruceType == 2;
      default:
        return true;
    }
  });

  //判断创建日期 和 名称 这里还是用switch
  switch (obj.sort) {
    case 1:
      classArray.sort((a, b) => b.updateDate - a.updateDate);
      break;
    case -1:
      classArray.sort((a, b) => a.updateDate - b.updateDate);
      break;
    case 2:
      classArray.sort((a, b) => a.name.localeCompare(b.name));
      return classArray;
    case -2:
      classArray.sort((a, b) => b.name.localeCompare(a.name));
      return classArray;
    case 3:
      classArray.sort(
        (a, b) => b.owner.nickName.length - a.owner.nickName.length
      );
      break;
    case -3:
      classArray.sort(
        (a, b) => a.owner.nickName.length - b.owner.nickName.length
      );
      break;
    case 4:
      classArray.sort((a, b) => a.createDate - b.createDate);
      return classArray;
    case -4:
      classArray.sort((a, b) => b.createDate - a.createDate);
      return classArray;
    case 5:
      classArray.sort((a, b) => a.colour - b.colour).reverse();
      break;
    case -5:
      classArray.sort((a, b) => a.colour - b.colour);
      break;
    default:
      log("跳过创建日期和名称");
      break;
  }

  //排序
  //循环过滤后的数组 sort 最后日期1 -1 所有者3 -3 标签5 -5
  let obj_sort = Math.abs(obj.sort);
  if (obj_sort == 1 || obj_sort == 3 || obj_sort == 5) {
    classArray.forEach(res => {
      let template = {
        //待用 （时间对象）
        option: []
      };
      //排序最后日期
      if (obj.sort == 1 || obj.sort == -1) {
        // log("最后日期筛选进入")

        let index = newArray.findIndex(
          rej => rej.updateDate == dateFilter(res.updateDate)
        );

        if (index == -1) {
          template.updateDate = dateFilter(res.updateDate);
          template.option.push(res);
          newArray.push(template);
        } else {
          newArray[index].option.push(res);
        }
      }

      if (obj.sort == 3 || obj.sort == -3) {
        if (!res.owner) {
          return;
        }

        let index = newArray.findIndex(
          rej => rej.nickName == res.owner.nickName
        );

        if (index == -1) {
          template = { ...res.owner, option: [] };
          template.option.push(res);
          newArray.push(template);
        } else {
          newArray[index].option.push(res);
        }
      }

      if (obj.sort == 5 || obj.sort == -5) {
        let index = newArray.findIndex(rej => rej.colour == res.colour);

        if (index == -1) {
          template.colour = res.colour;
          template.option.push(res);
          newArray.push(template);
        } else {
          newArray[index].option.push(res);
        }
      }
    });
    return newArray;
  }
  return classArray;
};

//时间排序
export const timeSort = (array = [], type = 0) => {
  // type  [判断正序还是倒叙] 这里type == 0 为倒叙 1 为正序

  let newArray = []; //待用

  let template = {
    //待用 （时间对象）
    time: "", //用来时间
    option: []
  };

  let arr = [...array]; //这里先数组展开，防止 被引用数据类型影响

  //照例 先遍历数组 把大小区分了

  if (type == 0) {
    arr.sort((a, b) => a.createDate - b.createDate);
  } else if (type == 1) {
    arr.sort((a, b) => b.createDate - a.createDate);
  }

  //排序完成 进行时间归纳

  arr.forEach(res => {
    let index = newArray.findIndex(
      rej =>
        new Date(rej.createDate).toLocaleDateString() ==
        new Date(res.createDate).toLocaleDateString()
    );

    if (index == -1) {
      template.time = res.createDate;
      template.option.push(res);
      newArray.push(template);
    } else {
      newArray[index].option.push(res);
    }
  });

  return newArray;
};
