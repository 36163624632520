import Vue from "vue";

/*
  命名规则:
  $开头 首字母大写驼峰
  eg: $SaveXppUserInfo
*/
let paperActiveDom = {};
let paperGridPathInfo = {};
let paperPageNodeGrid = {};
let paperDropProhibitHistory = {};
let picturePreviewData = { type: false, data: {} };
function EachSetObjKey(obj, val = false) {
  for (let key in obj) {
    obj[key] = val;
  }
}
// 已存在的轨迹节点
let pageOldTrajectory = {};
// 鼠标轨迹的运行动画
function trajectoryAnimate(page, mousemove) {
  let uid = mousemove.dataset.uid;
  let trajectory = pageOldTrajectory[uid] || [];
  // 当前标签页未激活时不显示其他人的光标
  if (!trajectory.length || document.visibilityState !== "visible") {
    page && mousemove && page.removeChild(mousemove);
    delete pageOldTrajectory[uid];
    return;
  }
  let rect = trajectory.shift();
  mousemove.style.cssText = `top:${rect.top};left:${rect.left}`;
  setTimeout(() => {
    trajectoryAnimate(page, mousemove);
  }, 300);
}

export default {
  $UpdataVuexState: (state, { key, data, local }) => {
    // 值为可枚举对象
    // console.log(Object.prototype.toString.call(data));
    switch (Object.prototype.toString.call(data)) {
      case "[object Object]":
        try {
          Object.assign(state[key], data);
          Vue.set(state, key, state[key]);
        } catch (err) {
          Vue.set(state, key, data);
        }
        break;
      case "[object Array]":
      case "[object DOMRect]":
        Vue.set(state, key, data);
        break;
      default:
        state[key] = data;
        break;
    }
    local && localStorage.setItem(key, JSON.stringify(state[key]));
  },
  $Logout: state => {
    localStorage.clear();
    sessionStorage.clear();
    state.xppUserInfo = null;
  },
  $SaveXppUserInfo: (state, data) => {
    state.xppUserInfo = data;
    localStorage.setItem("XppUserInfo", JSON.stringify(data));
  },
  $DiaLoginFoUpDate: (state, data) => {
    if (!data) {
      EachSetObjKey(state.dialogElementShow, false);
      state.xppPaperPassword.show = false;
      state.xppPaperPassword.state = false;
      return;
    }
    let { key, value, exclusive } = data;
    if (exclusive) {
      EachSetObjKey(state.dialogElementShow, false);
    }
    state.dialogElementShow[key] = value;
  },
  $SetViewScale: (state, data) => {
    if (!state.homeViewScale) {
      state.homeViewScaleReset = data;
    }
    state.homeViewScale = data;
  },
  $SetNoteInfo: (state, data) => {
    Object.assign(state.noteDefalutInfo, data);
    localStorage.setItem(
      "xppNoteInfoConfig",
      JSON.stringify(state.noteDefalutInfo)
    );
  },
  $SetEditToolbarType: (state, data) => {
    state.activeRemovePath = false;
    state.editToolbarActive = data;
  },
  // page重新排序
  $PageIndexSort: (state, { sort, arr }) => {
    let viewPage = [];
    for (let i = 0; i < sort.length; i++) {
      for (let o = 0; o < arr.length; o++) {
        let item = arr[o];
        if (item.page_id === sort[i]) {
          item.mousemoveHistory = {};
          viewPage.push(item);
          break;
        }
      }
    }
    state.paperViewAll = viewPage;
  },
  // 修改通道ID
  $SetPaperGroupId: (state, data) => {
    state.paperGroupId = data;
  },
  // 修改通道频段
  $SetPaperChannelId: (state, data) => {
    state.paperChannelId = data;
    sessionStorage.setItem("paperChannelId", data);
  },
  // 设置paper内数据
  $SetPaperViewAll: (state, data) => {
    for (let i of data) {
      i.mousemoveHistory = {};
    }
    state.paperViewAll = data;
  },
  // 存储最近被修改的 page 信息
  $SetPaperPageInfo: (state, index) => {
    // 如果滚动期间page操作菜单为开启状态，不更新page信息
    if (state.dialogElementShow.pageMoreMenu) return;
    let pageInfo = state.paperViewAll[index];
    if (!pageInfo) return;
    pageInfo = JSON.parse(JSON.stringify(state.paperViewAll[index]));
    pageInfo.page_index = index;
    state.paperPageInfo = pageInfo;
  },
  // 滚动至目标页
  $PaperScrollToIndex(state, id) {
    let tg = document.getElementById(id);
    if (!tg) return;
    tg.parentElement.parentElement.scrollIntoView();
  },
  // 更新page内信息
  $UpDatePaperPage: (state, data) => {
    let obj = data[data.type];
    switch (data.type) {
      case "pageSize":
        state.paperViewZoomInfo = obj;
        break;
      default:
        Vue.set(state.paperViewAll[data.index], data.type, obj);
        break;
    }
  },
  // 接收ws消息后设置paper_page内元素的数据
  $SetPaperPageView: (state, data) => {
    if (data instanceof Array) {
      for (let i = 0; i < data.length; i++) {
        let info = data[i].data;
        let dd = info[info.type][0];
        let obj = dd[dd.id];
        Vue.set(state.paperViewAll[obj.page_index][obj.type], dd.id, obj);
      }
    } else {
      let dd = data[data.type][0];
      let obj = dd[dd.id];
      Vue.set(state.paperViewAll[obj.page_index][obj.type], dd.id, obj);
    }
  },
  // 直接改动paper内节点数据
  $UpdatePaperElementDate(state, data) {
    if (data instanceof Array) {
      for (let i = 0; i < data.length; i++) {
        let item = data[i];
        Vue.set(state.paperViewAll[item.page_index][item.type], item.id, item);
      }
    } else {
      Vue.set(state.paperViewAll[data.page_index][data.type], data.id, data);
    }
  },
  // 删除paper_page内元素的数据
  $DelPaperPageView: (state, data) => {
    if (data instanceof Array) {
      for (let i = 0; i < data.length; i++) {
        let { type, data: obj } = data[i];
        try {
          Vue.delete(state.paperViewAll[obj.index][type], obj[type][0].id);
        } catch (err) {
          console.error(err);
        }
      }
    } else {
      let { type, index } = data;
      Vue.delete(state.paperViewAll[index][type], data[type][0].id);
    }
  },
  // 清空paper_page内某个字段
  $DelPageElement: (state, data) => {
    Vue.set(state.paperViewAll[data.index], data.type, {});
  },
  // paper 内新增 page
  $PushPaperPage: (state, { data, idx }) => {
    if (idx === 0) {
      state.paperViewAll.unshift(data);
    } else if (idx > 0) {
      state.paperViewAll.splice(idx + 1, 0, data);
    } else {
      state.paperViewAll.push(data);
    }
  },
  // paper 内删除 page
  $SplicePaperPage: (state, index) => {
    state.paperViewAll.splice(index, 1);
  },
  // 存入被选中节点的信息
  $SetPaperActiveDom: (state, data = paperActiveDom) => {
    if (!(data instanceof Array) && data.index) {
      let { index, type, id } = data;
      Vue.set(
        state,
        "paperActiveDom",
        JSON.parse(JSON.stringify(state.paperViewAll[index][type][id]))
      );
    } else {
      Vue.set(state, "paperActiveDom", JSON.parse(JSON.stringify(data)));
    }
  },
  // 设置辅助线信息
  $SetPaperGridPathInfo: (state, data) => {
    state.paperGridPathInfo = data || paperGridPathInfo;
  },
  // 保存page下节点的坐标集合
  $SetPaperPageNodeGrid: (state, data) => {
    state.paperPageNodeGrid = data || paperPageNodeGrid;
  },
  // 查看评论详情
  $SaveElementCommentInfo(state, data) {
    Vue.set(state, "elementCommentInfo", data || {});
  },
  // 移除一条评论
  $DeleteOneComment(state, { commentId, comments }) {
    let { elementCommentInfo } = state;
    if (
      !elementCommentInfo.targetData ||
      elementCommentInfo.commentId !== commentId
    )
      return;
    let { list } = elementCommentInfo;
    let targetId = comments[0].id;
    for (let i = 0; i < list.length; i++) {
      let item = list[i];
      if (item.id === targetId) {
        list.splice(i, 1);
        break;
      }
    }
    // Vue.set(elementCommentInfo, "list", list);
    // console.log(list);
  },
  // 更新通知信息数量
  $SaveNoticeListPageInfo(state, data) {
    if (data) {
      state.noticeListPageInfo.unReadCount = data - 0;
    } else {
      state.noticeListPageInfo.unReadCount--;
    }
    Vue.set(
      state.noticeListPageInfo,
      "unReadCount",
      state.noticeListPageInfo.unReadCount
    );
  },
  // 更新在线人员列表
  $UpdatePaperPeopleOnlineList(state, { type, data }) {
    if (type) {
      if (data.userId !== state.xppUserInfo.id) {
        // 不是自己
        let off = true;
        // 列表内未存在
        for (let i in state.paperPeopleOnlineList) {
          if (data.userId === state.paperPeopleOnlineList[i].userId) {
            off = false;
          }
        }
        off && state.paperPeopleOnlineList.push(data);
      }
    } else {
      state.paperPeopleOnlineList = state.paperPeopleOnlineList.filter(item => {
        return item.userId !== data.userId;
      });
    }
  },
  // 更新鼠标移动信息
  $UpdatePaperMouserMoveInfo(state, data) {
    if (document.visibilityState !== "visible") return; // 当前标签页未激活时不显示其他人的光标
    let { index, userId, mousemoveRecord } = data;
    if (!state.xppUserInfo || userId === state.xppUserInfo.id) {
      return;
    }
    let { page_id } = state.paperViewAll[index];
    let page = document.getElementById(page_id);
    if (!page) return;
    page = page.parentElement;
    let oldMove = pageOldTrajectory[userId];
    // 已存在轨迹时，向轨迹节点内追加数据
    if (oldMove) {
      pageOldTrajectory[userId] = oldMove.concat(oldMove, mousemoveRecord);
      return;
    }
    let mousemove = document.createElement("div");
    mousemove.className = "xpp-paper-mousemove-record";
    mousemove.innerHTML = `<div><div class="xpp-user-photo" style="background-image:url(${data.headImg});"></div><span>${data.nickName}</span></div>`;
    mousemove.dataset.uid = userId;
    pageOldTrajectory[userId] = mousemoveRecord;
    page.appendChild(mousemove);
    mousemove.classList.add("xpp-trajectory");
    trajectoryAnimate(page, mousemove);
  },
  // 设置元素禁用状态
  $SetElementOperateProhibit(state, { ids, type, userId, headImg }) {
    if (userId === state.xppUserInfo.id) {
      return;
    }
    if (paperDropProhibitHistory[userId]) {
      let arr = paperDropProhibitHistory[userId];
      for (let i = 0; i < arr.length; i++) {
        let dom = document.getElementById(arr[i]);
        if (!dom) {
          continue;
        } else {
          dom.classList.remove("xpp-select-nodrop");
          dom.getElementsByClassName(
            "userdrop-select-info"
          )[0].style.backgroundImage = `url(${headImg})`;
        }
      }
    }
    if (ids.length > 0) {
      paperDropProhibitHistory[userId] = ids;
    } else if (paperDropProhibitHistory[userId]) {
      ids = paperDropProhibitHistory[userId];
    }
    for (let i = 0; i < ids.length; i++) {
      let dom = document.getElementById(ids[i]);
      if (!dom) {
        continue;
      } else {
        type
          ? dom.classList.add("xpp-select-nodrop")
          : dom.classList.remove("xpp-select-nodrop");
        dom.getElementsByClassName(
          "userdrop-select-info"
        )[0].style.backgroundImage = `url(${headImg})`;
      }
    }
    let nodrop = document.getElementsByClassName("xpp-select-nodrop");
    state.paperDragOptions.disabled = !!nodrop.length; // 缩略图导航拖动禁用状态
  },
  // 恢复旧版本文本内容
  $RecoveryEditHistoryInfo(state, params) {
    let { id, type, page_index, content } = params;
    let data = state.paperViewAll[page_index][type][id];
    this.commit("$AddXppOperationRecord", { type: "update", data });
    data.content = content;
  },
  // 节点移入其他paper
  $ElementCheckBatchMoveTarget(state, data) {
    for (let item of data) {
      let { type, id, page_index } = item.data[item.type][0][
        item.data[item.type][0].id
      ];
      Vue.delete(state.paperViewAll[page_index][type], id);
    }
  },
  // 控制邀请加入弹窗显示/隐藏
  $DialogInvitationToJoinShow(state, data = { visible: false }) {
    data.member =
      data.type === 1
        ? state.xppResourceMemberList.memberlist
        : state.xppResourceMemberList.partnerlist;
    Object.assign(state.dialogInvitationToJoin, data);
  },
  // 操作记录增加
  $AddXppOperationRecord(state, data) {
    let [arr1] = state.xppOperationRecord;
    arr1.unshift(JSON.stringify(data));
    if (arr1.length > 30) {
      arr1 = arr1.splice(31, arr1.length);
    }
  },
  // 操作记录变化 - 清空
  $RemoveXppOperationRecord(state) {
    state.xppOperationRecord = [[], []];
  },
  // 操作记录变化 - 撤销
  $RevokeXppOperationRecord(state, data) {
    let [arr1, arr2] = state.xppOperationRecord;
    arr1.shift();
    arr2.unshift(JSON.stringify(data));
  },
  // 操作记录变化 - 恢复
  $RecoveryXppOperationRecord(state, data) {
    let [arr1, arr2] = state.xppOperationRecord;
    arr2.shift();
    arr1.unshift(JSON.stringify(data));
  },
  // 显示图片预览窗口
  $XppPicturePreviewShow(state, { type, data } = picturePreviewData) {
    state.showPicturePreview = type;
    state.triggerImgInfo = data;
  },
  // 切换幻灯片放映的准备状态
  $ChangeSlidePlayerState(state, data) {
    state.xppSlidePlayerReady = data;
  },
  // 切换幻灯片放映的准备状态
  $SetPaperPassword(state, data) {
    Object.assign(state.xppPaperPassword, data);
  },
  //切换展示状态
  $tagRowOrCell(state, data) {
    state.rowOrCell = data;
    localStorage.setItem("rowOrCell", JSON.stringify(data));
  },
  //退出项目
  $outProject(state, data) {
    state.outProject = data;
  },
  $setContextMenu(state, data) {
    state.contextMenuType = { ...data };
  },
  $setRefresh(state) {
    state.refresh = false;
    setTimeout(() => {
      state.refresh = true;
    }, 300);
  },
  $setGlobalUploadStatys(state) {
    state.globalUploadStatus = !state.globalUploadStatus;
  },
  // 清空联系人过滤信息
  $RemoveXppContactsFilterInfo(state) {
    Object.assign(state.xppContactsFilterInfo, {
      selection: null,
      text: ""
    });
  },
  // 更新鼠标点击记录
  $UpdateLastEventCoordinate(state, { event, paperPage }) {
    if (!paperPage) return;
    let { top, left, width, height } = paperPage.getBoundingClientRect();

    if (!event) {
      event = {
        top,
        left,
        width,
        height,
        pageX: left,
        pageY: top,
        target: paperPage
      };
    }
    let { pageX, pageY, target } = event;
    let x = Math.floor(pageX - left);
    let y = Math.floor(pageY - top);
    Vue.set(
      state,
      "lastEventCoordinate",
      Object.assign(state.lastEventCoordinate, {
        top: Math.floor(y / state.homeViewScale),
        left: Math.floor(x / state.homeViewScale),
        x,
        y,
        pageX,
        pageY,
        target,
        paperPage
      })
    );
  },
  // 设置loading状态
  $PaperLoadingState(state, data) {
    Vue.set(state, "xcanvasReadyState", !data);
  }
};
