"use strict";

import Vue from "vue";
import axios from "axios";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  baseURL: "https://dev.x-canvas.net",
  timeout: 60 * 1000, // Timeout
  withCredentials: false, // Check cross-site Access-Control
  crossDomain: true
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  config => {
    let token = localStorage.getItem("token");
    let paperChannelId = sessionStorage.getItem("paperChannelId");
    if (token) {
      config.headers["AUTH-TOKEN"] = token;
    }
    if (paperChannelId) {
      config.headers["channelId"] = paperChannelId;
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    switch (response.data.status) {
      case 200:
        return response.data;
      case 401:
        return (location.pathname = "/error_view/401");
      case 421:
        if (location.pathname.indexOf("/passport/") >= 0) return;
        localStorage.setItem("xppLoginMsg", "登录信息失效，请重新登录");
        return location.replace("/passport");
      case 432:
        console.warn(response.data);
        break;
      default:
        if (!response.data.message) break;
        window.ELEMENT.Message({
          type: "error",
          message: response.data.message
        });
        console.error(response.data);
        break;
    }
    return response.data;
  },
  function(error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

Plugin.install = function(Vue) {
  Vue.$axios = _axios;
  window.$axios = _axios;
  Object.defineProperties(Vue.prototype, {
    $axios: {
      get() {
        return _axios;
      }
    }
  });
};

Vue.use(Plugin);

export default _axios;
