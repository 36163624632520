var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"contextmenu-submenu-fade"}},[(_vm.visible)?_c('div',{ref:"menu",class:[_vm.commonClass.menu, _vm.$style.menu, _vm.customClass],style:({
      left: _vm.style.left + 'px',
      top: _vm.style.top + 'px',
      minWidth: _vm.style.minWidth + 'px',
      zIndex: _vm.style.zIndex
    })},[_c('div',{class:_vm.$style.menu_body},[_vm._l((_vm.items),function(item,index){return [(!item.hidden)?[(item.disabled)?_c('div',{key:index,class:[
              _vm.commonClass.menuItem,
              _vm.commonClass.unclickableMenuItem,
              _vm.$style.menu_item,
              _vm.$style.menu_item__disabled
            ]},[(_vm.hasIcon)?_c('div',{class:_vm.$style.menu_item_icon},[(item.icon)?_c('i',{class:item.icon}):_vm._e()]):_vm._e(),_c('span',{class:_vm.$style.menu_item_label},[_vm._v(_vm._s(item.label))]),_c('div',{class:_vm.$style.menu_item_expand_icon})]):(item.children)?_c('div',{key:index,class:[
              _vm.commonClass.menuItem,
              _vm.commonClass.unclickableMenuItem,
              _vm.$style.menu_item,
              _vm.$style.menu_item__available,
              _vm.activeSubmenu.index === index ? _vm.$style.menu_item_expand : null
            ],on:{"mouseenter":function ($event) { return _vm.enterItem($event, item, index); }}},[(_vm.hasIcon)?_c('div',{class:_vm.$style.menu_item_icon},[(item.icon)?_c('i',{class:item.icon}):_vm._e()]):_vm._e(),_c('span',{class:_vm.$style.menu_item_label},[_vm._v(_vm._s(item.label))]),_c('div',{class:_vm.$style.menu_item_expand_icon},[_vm._v("▶")])]):_c('div',{key:index,class:[
              _vm.commonClass.menuItem,
              _vm.commonClass.clickableMenuItem,
              _vm.$style.menu_item,
              _vm.$style.menu_item__available
            ],on:{"mouseenter":function ($event) { return _vm.enterItem($event, item, index); },"click":function($event){return _vm.itemClick(item)}}},[(_vm.hasIcon)?_c('div',{class:_vm.$style.menu_item_icon},[(item.icon)?_c('i',{class:item.icon}):_vm._e()]):_vm._e(),_c('span',{class:_vm.$style.menu_item_label},[_vm._v(_vm._s(item.label))]),_c('div',{class:_vm.$style.menu_item_expand_icon})]),(item.divided)?_c('div',{key:(index + "-br"),class:_vm.$style.menu_item_hr}):_vm._e()]:_vm._e()]})],2)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }