import Quill from "quill";
import Parchment from "parchment";

var Embed = Quill.import("blots/embed");
let FontStyle = Quill.import("attributors/style/font");
let SizeStyle = Quill.import("attributors/style/size");
let ColorStyle = Quill.import("attributors/style/color");
let BackgroundStyle = Quill.import("attributors/style/background");
let Link = Quill.import("formats/link");

FontStyle.scope = Parchment.Scope.BLOCK;
FontStyle.whitelist = [
  "yahei",
  "simsun",
  "simhei",
  "kaiti",
  "siliguri",
  "libre",
  "opensans",
  "robotomono",
  "robotoslab",
  "monospace",
  "serif"
];

SizeStyle.scope = Parchment.Scope.BLOCK;
SizeStyle.whitelist = [
  "22px",
  "26px",
  "28px",
  "30px",
  "46px",
  "52px",
  "58px",
  "72px",
  "92px",
  "112px"
];

ColorStyle.scope = Parchment.Scope.BLOCK;

BackgroundStyle.scope = Parchment.Scope.BLOCK;

class MentionBlot extends Embed {
  static create(value) {
    let node = super.create(value);
    node.setAttribute("contenteditable", "false");
    node.classList.add("xpp-mention");
    node.innerText = `@${value.name}`;
    node.dataset.label = `${value.name}`;
    node.dataset.userId = `${value.id}`;
    node.dataset.mid = `${value.mid}`;
    return node;
  }
  static value(domNode) {
    return {
      name: domNode.dataset.label,
      id: domNode.dataset.userId,
      mid: domNode.dataset.mid
    };
  }
}

MentionBlot.blotName = "mention";
MentionBlot.tagName = "B";

Link.create = function(value) {
  let node = document.createElement("a");
  value = Link.sanitize(value);
  node.setAttribute("href", value);
  node.setAttribute("target", "_blank");
  return node;
};

Quill.register(
  {
    "formats/font": FontStyle,
    "formats/size": SizeStyle,
    "formats/color": ColorStyle,
    "formats/background": BackgroundStyle,
    "formats/mention": MentionBlot,
    "formats/link": Link
  },
  true
);

export default Quill;
