import Vue from "vue";
import axios from "@plugins/axios";
import Quill from "@plugins/quill.js";
import {
  parentLocation,
  commentDialogOffset,
  creatElementId,
  formParamsFormat,
  ElementFindIdx,
  ElementIdxGetChild,
  PaperGetElementById
} from "@plugins/toolWheel";

let PaperWSInfo = {
  ws: null,
  url() {
    return "wss://ws.x-canvas.net";
  },
  count: 0,
  groupId: "",
  channelId: null,
  onLineBind: false
};

let XppWsIntervalData = {
  paper: null,
  init(state, user) {
    state.xcanvasReadyState = true;
    PaperWSInfo.count = 0;
    PaperWSInfo.groupId = state.paperGroupId;
    PaperWSInfo.channelId = state.paperChannelId;
    PaperWSInfo.modifier = user.id;
    PaperWSInfo.access_token = user.token;
    // 心跳信息
    this.paper = JSON.stringify({
      action: "PING",
      groupId: "ping-group",
      channelId: PaperWSInfo.channelId,
      data: {
        paperId: PaperWSInfo.groupId,
        companyId: user.companyId,
        email: user.email,
        headImg: user.headImg,
        userId: user.id,
        nickName: user.nickName
      }
    });
  }
};
// 全局心跳
const timeInterval = 60 * 1000;
setInterval(() => {
  let { paper } = XppWsIntervalData;
  if (paper && PaperWSInfo.ws) {
    PaperWSInfo.ws.readyState === 1 && PaperWSInfo.ws.send(paper);
  }
}, timeInterval);

let dialogElementShow;
let handlerDefault = () => {
  if (!dialogElementShow.xppContactListShow) {
    return true;
  } else {
    return false;
  }
};
const quillKeyboard = {
  bindings: {
    tab: {
      key: 9,
      handler() {
        if (!dialogElementShow.xppContactListShow) {
          this.quill.history.cutoff();
        }
      }
    },
    contactEnter: {
      key: 13,
      handler: handlerDefault
    },
    contactLeft: {
      key: 37,
      handler: handlerDefault
    },
    contactUp: {
      key: 38,
      handler: handlerDefault
    },
    contactRight: {
      key: 39,
      handler: handlerDefault
    },
    contactDown: {
      key: 40,
      handler: handlerDefault
    }
  }
};

window.addEventListener("offline", () => {
  console.warn("网络连接中断", PaperWSInfo.onLineBind);
  PaperWSInfo.context && PaperWSInfo.context.dispatch("$PaperWSClose");
  if (PaperWSInfo.onLineBind) return; // 绑定过就不再绑定了
  // 监听网络恢复
  window.addEventListener("online", () => {
    location.reload(); // 恢复网络后刷新页面
    // console.warn("网络连接恢复");
    // PaperWSInfo.ws = null;
    // PaperWSInfo.count = null;
    // PaperWSInfo.context && PaperWSInfo.context.dispatch("$PaperWebSocket");
  });
  PaperWSInfo.onLineBind = true;
});

export default {
  // paper内消息长链接
  $PaperWebSocket(context) {
    if (PaperWSInfo.ws && PaperWSInfo.groupId === context.state.paperGroupId) {
      return;
    }
    let user = context.state.xppUserInfo;
    let ws = null;
    if (PaperWSInfo.ws && PaperWSInfo.ws.readyState === 1) {
      XppWsIntervalData.init(context.state, user);
      ws = PaperWSInfo.ws;
      ws.send(JSON.stringify({ action: "BIND" }));
      return ws;
    } else {
      ws = new WebSocket(PaperWSInfo.url());
    }

    ws.onopen = () => {
      ws.send(JSON.stringify({ action: "BIND" }));
    };
    ws.onmessage = e => {
      let msg = JSON.parse(e.data);
      let { data, channelId, dataarray } = msg;
      msg.type = msg.type || msg.action;
      switch (msg.type.toLocaleLowerCase()) {
        case "bind":
          console.log("Binding Success");
          context.commit("$SetPaperChannelId", channelId);
          XppWsIntervalData.init(context.state, user);
          context.dispatch("$OnlineStateChange", true);
          break;
        case "put":
          context.commit("$SetPaperPageView", data);
          break;
        case "batchpush":
          context.commit("$SetPaperPageView", dataarray);
          break;
        case "delete":
          context.commit("$DelPaperPageView", data);
          break;
        case "delelement":
          context.commit("$DelPageElement", data);
          break;
        case "batchdelete":
          context.commit("$DelPaperPageView", dataarray);
          break;
        case "new_page":
          context.commit("$PushPaperPage", { data, idx: msg.index });
          break;
        case "del_page":
          context.commit("$SplicePaperPage", data.index);
          break;
        case "updatepaper":
          context.commit("$UpDatePaperPage", data);
          break;
        case "pagesort":
          context.commit("$PageIndexSort", {
            sort: msg.msgInfo,
            arr: context.state.paperViewAll
          });
          break;
        case "commentpush":
        case "commentupdate":
          context.dispatch("$UpDataCommentInfo", {
            elementId: data._id,
            commentId: data.commentId
          });
          break;
        case "commentdelete":
          context.commit("$DeleteOneComment", data);
          break;
        case "onlineadd":
          context.commit("$UpdatePaperPeopleOnlineList", { type: true, data });
          break;
        case "onlinedelete":
          context.commit("$UpdatePaperPeopleOnlineList", { type: false, data });
          break;
        case "papermousemove":
          context.commit("$UpdatePaperMouserMoveInfo", data);
          break;
        case "disabledelement":
          context.commit("$SetElementOperateProhibit", data);
          break;
        case "editrestore":
          context.commit("$RecoveryEditHistoryInfo", data);
          break;
        case "batchmove":
          context.commit("$ElementCheckBatchMoveTarget", dataarray);
          break;
        case "online_company": // 更新团队内当前在线人员列表
          context.dispatch("$UpdateCompanyOnlineList", data);
          break;
        case "remind":
          for (let i in data.reciveuserIdList) {
            if (data.reciveuserIdList[i] === context.state.xppUserInfo.id) {
              setTimeout(() => {
                context.dispatch("$UpdataNoticeListInfo");
              }, 1000);
            }
          }
          break;
        case "remindcountchange":
          console.log(
            "收到了未读消息数量变更的通知：" + data.remindUnreadCount
          );
          console.log(data);
          context.commit("$SaveNoticeListPageInfo", data.remindUnreadCount);
          break;
        case "updateteam": // 团队成员列表有变动
          context.dispatch("$UpdataTeamMemberList", true);
          break;
        case "loginsuccess": // 登录成功后返回的团队成员列表更新时间
          data.remind_unread_count &&
            context.commit("$SaveNoticeListPageInfo", data.remind_unread_count);
          context.dispatch("$getPaperPeopleOnlineList", data.paper_online_list);
          if (data.invite_remind) {
            context.state.joinTheTeamDialog.state = true;
            context.state.joinTheTeamDialog.info = data.invite_remind;
          }
          if (
            localStorage.getItem("team_update_time") - 0 <
            data.team_update_time - 0
          ) {
            context.dispatch("$UpdataTeamMemberList", true);
          }
          localStorage.setItem("team_update_time", data.team_update_time);
          break;
        case "inviteaddteam": // 收到其它团队的加入邀请
          context.state.joinTheTeamDialog.state = true;
          context.state.joinTheTeamDialog.info = data;
          break;
        case "setloading": // 同步loading状态 - 仅操作需要其他页面等待时出现
          context.commit("$PaperLoadingState", data);
          break;
      }
    };

    ws.onerror = err => {
      ws.close();
      if (location.pathname.indexOf("/paper/") < 0) return;
      window.ELEMENT.Notification({
        title: "警告",
        message:
          "数据通信错误，与服务器的连接已断开。 30s 后自动重连；重连之前的所有操作可能都不会被记录！",
        type: "warning"
      });
      throw err;
    };

    ws.onclose = info => {
      context.state.xcanvasReadyState = false;
      ws = null;
      PaperWSInfo.ws = null;
      PaperWSInfo.count = 0;
      if (info.code === 1000) return;
      // 网络断开
      if (!navigator.onLine) {
        return;
      }
      console.warn("paper长链接关闭");
      console.dir(info);
      if (PaperWSInfo.count < 5) {
        setTimeout(() => {
          PaperWSInfo.count++;
          context.dispatch("$PaperWebSocket");
        }, 3000);
      } else {
        window.ELEMENT.Notification({
          title: "警告：" + info.code,
          message: "网络连接失败次数过多,请稍后重试或联系服务人员",
          type: "warning"
        });
      }
    };
    PaperWSInfo.ws = ws;
    PaperWSInfo.context = context;
    return ws;
  },
  // 长链接发送消息
  $WsSendMsg({ state }, msg) {
    let { ws } = PaperWSInfo;
    if (!ws || (ws && ws.readyState !== 1)) return;
    msg.groupId = PaperWSInfo.groupId;
    msg.channelId = PaperWSInfo.channelId;
    msg.modifier = PaperWSInfo.modifier;
    msg.access_token = PaperWSInfo.access_token;
    switch (msg.action) {
      case "PUT":
        let type = msg.data.type;
        let data = msg.data[type][0][msg.data[type][0].id];
        data.updataInfo = {
          userId: msg.modifier,
          nickName: state.xppUserInfo.nickName,
          time: new Date().getTime().toString(),
          headImg: state.xppUserInfo.headImg
        };
        switch (data.type) {
          case "paperSequence":
          case "hyperlinkSequence":
            msg.data[type][0].canvasthumb = data.canvasthumb;
            msg.data[type][0].canvasthumbup = data.canvasthumbup;
            break;
        }
        break;
      case "DISABLEDELEMENT":
        Object.assign(msg.data, {
          userId: state.xppUserInfo.id,
          headImg: state.xppUserInfo.headImg
        });
        break;
    }
    ws.send(JSON.stringify(msg));
  },
  // 长链接关闭
  $PaperWSClose() {
    let { ws } = PaperWSInfo;
    if (!ws || (ws && ws.readyState !== 1)) return;
    ws.close(1000);
    setTimeout(() => {
      // 网络已经断开，但是wsclose事件没有响应
      if (PaperWSInfo.ws) {
        PaperWSInfo.count = 0;
        PaperWSInfo.context.state.xcanvasReadyState = false;
        PaperWSInfo.ws = null;
      }
    }, 100);
  },
  // 在线状态
  $OnlineStateChange(context, type) {
    let user = context.state.xppUserInfo;
    if (!user) return;
    context.dispatch("$WsSendMsg", {
      action: type ? "ONLINEADD" : "ONLINEDELETE",
      data: {
        paperId: context.state.paperGroupId, //必须
        companyId: user.companyId,
        email: user.email,
        headImg: user.headImg,
        userId: user.id,
        nickName: user.nickName
      }
    });
    if (!type) {
      context.state.paperGroupId = "";
    }
  },
  // 新建paper
  async $CreateNewPaper({ state }, data) {
    let { info } = await axios.get("/dis/p/id");
    let { width, height } = state.paperViewZoomInfo;
    await axios.post(
      "/dis/p/new",
      formParamsFormat(
        Object.assign(data, {
          paperId: info.id,
          width,
          height,
          ownProjectId: state.paperCategoryList[0].id,
          ownPaperId: state.paperCategoryList[1].id
        })
      )
    );
    return `${info.id}`;
  },
  // 更新评论列表
  async $UpDataCommentInfo(context, { elementId, commentId }) {
    let elementCommentInfo = context.state.elementCommentInfo;
    if (
      !elementCommentInfo.targetData ||
      commentId !== elementCommentInfo.commentId
    ) {
      return;
    }
    let { data: commentList } = await axios.get("/mo/comment/get", {
      params: {
        id: elementId,
        commentId
      }
    });

    if (commentList) {
      Vue.set(elementCommentInfo, "list", commentList.comments);
      Vue.nextTick().then(() => {
        // DOM 更新了
        let dom = document.getElementById("xppCommentContentBody");
        dom && dom.scrollTo(0, dom.scrollHeight);
      });
    }
  },
  // 通知列表
  async $UpdataNoticeListInfo(context, pageInfo) {
    let { state } = context;
    let { pageNum, pageSize } = Object.assign(
      state.noticeListPageInfo,
      pageInfo
    );

    let res = await axios.get("/dis/rem/list", {
      params: {
        userId: state.xppUserInfo.id,
        pageNum,
        pageSize
      }
    });
    let { data } = res;
    context.commit("$SaveNoticeListPageInfo", res.remind_unread_count);
    let idx = 0;
    let idx1 = 0;
    let arr = [];
    for (let i in data) {
      let item = data[i];
      if (item.remindType === 3) {
        continue;
      }
      if (!arr[idx]) {
        arr[idx] = {
          time: item.remindDate,
          list: []
        };
      }
      if (idx1 > 1) {
        let date1 = new Date(data[idx1 - 1].remindDate).toDateString();
        let date2 = new Date(item.remindDate).toDateString();
        if (date1 === date2) {
          arr[idx].list.push(item);
        } else {
          idx++;
          if (!arr[idx]) {
            arr[idx] = {
              time: item.remindDate,
              list: []
            };
          }
          arr[idx].list.push(item);
        }
      } else {
        arr[idx].list.push(item);
      }
      idx1++;
    }
    if (pageNum === 1) {
      state.noticeListInfo = arr;
      state.noticeListPageInfo.currentTotal = data.length;
    } else {
      state.noticeListInfo = state.noticeListInfo.concat(arr);
      state.noticeListPageInfo.currentTotal += data.length;
    }
    state.noticeListPageInfo.pageNum = res.pageNum - 0;
    state.noticeListPageInfo.total = res.total;
  },
  // 打开节点评论窗口
  async $OpenElementCommentDialog(
    context,
    { dom, elementId, commentId, parentEl }
  ) {
    let targetData =
      context.state.paperViewAll[dom.dataset.index][dom.dataset.type][
        elementId
      ];
    let commentEl = dom.querySelector(`[data-comments="${commentId}"]`);

    let info = {
      commentId,
      targetData,
      offset: commentDialogOffset({
        target: dom,
        parentEl,
        commentEl,
        homeViewScale: context.state.homeViewScale
      }),
      list: []
    };

    context.commit("$SaveElementCommentInfo", info);
    await context.dispatch("$UpDataCommentInfo", {
      elementId,
      commentId
    });
    context.commit("$DiaLoginFoUpDate", {
      key: "xppCommentShow",
      value: true,
      exclusive: true
    });
  },
  // 跳转到通知目标节点
  async $NavToTargetElement(context) {
    let notiInfo = JSON.parse(localStorage.getItem("notiNavigatorInfo"));
    if (!notiInfo) {
      return;
    }
    let { commentId, elementId, elementType } = notiInfo;
    let dom = document.getElementById(elementId);
    console.log(dom);
    if (!dom) {
      let nodeData = PaperGetElementById(elementId, context.state.paperViewAll);
      console.log(nodeData);
      if (nodeData) {
        document.getElementById(nodeData.page_id).scrollIntoView();
        setTimeout(() => {
          console.log(123);
          context.dispatch("$NavToTargetElement");
        }, 100);
      } else {
        localStorage.removeItem("notiNavigatorInfo");
      }
      return;
    }
    localStorage.removeItem("notiNavigatorInfo");
    let parentEl = parentLocation(dom, ".xpp-paper-page");
    dom.classList.add("focusing");
    function focus() {
      dom.classList.remove("focusing");
      dom.removeEventListener("mouseover", focus);
    }
    dom.addEventListener("mouseover", focus);
    dom.scrollIntoView();
    switch (elementType) {
      case "comment":
        await context.dispatch("$OpenElementCommentDialog", {
          dom,
          elementId,
          commentId,
          parentEl
        });
        break;
      case "focusing":
        break;
    }
  },
  // 获取当前paper在线人员列表
  async $getPaperPeopleOnlineList({ state }, list) {
    if (!list) return;
    state.paperPeopleOnlineList = list.filter(item => {
      return item.userId !== state.xppUserInfo.id;
    });
  },
  // 对待通知列表内人员发送通知
  async $SendReciveuserNoti({ state }, delta) {
    let { container, editMentionId } = state.xppQuillEditContainer;
    let reciveuserIdList = [];
    for (let i in delta.ops) {
      let mention = delta.ops[i].insert.mention;
      if (mention && mention.mid === editMentionId) {
        reciveuserIdList.push(mention.id);
      }
    }
    if (!reciveuserIdList.length) {
      return;
    }
    let targetData = container.parentElement;
    let paper = state.paperCategoryList.slice(-1)[0];
    await axios.post("/dis/rem/add", {
      elementId: targetData.id,
      paperId: paper.id,
      paperName: paper.name,
      projectId: state.paperCategoryList[0].id,
      projectName: state.paperCategoryList[0].name,
      reciveuserIdList,
      remindType: "2",
      reminduserHeadimg: state.xppUserInfo.headImg,
      reminduserId: state.xppUserInfo.id,
      reminduserName: state.xppUserInfo.nickName
    });
  },
  // 编辑器初始化
  async $QuillInit({ state }, target) {
    let editToolbar = document.getElementById("xppToolbarContainer");
    editToolbar.innerHTML = state.editToolbarInnerHtml;
    let quill = await new Quill(target, {
      modules: {
        toolbar: {
          container: "#xppToolbarContainer"
        },
        keyboard: quillKeyboard,
        clipboard: {
          matchVisual: false
        }
      },
      theme: "snow"
    });
    quill.editMentionId = creatElementId("mention");
    quill.theme.tooltip.root.style.display = "none"; // 隐藏工具栏
    return quill;
  },
  // 变更富文本编辑器中复选框状态
  async $QuillCheckListChange({ dispatch }, { target, parent }) {
    if (target.tagName !== "LI") return;
    let parentElement = parent.parentElement;
    let idx = ElementFindIdx(target, parent);
    let quill = await dispatch("$QuillInit", parent);
    let container = quill.container;
    let dd = ElementIdxGetChild(
      container.getElementsByClassName("ql-editor")[0],
      idx
    );
    let blot = Quill.find(dd);
    let index = quill.getIndex(blot);
    quill.setSelection(index, 0);
    let format = dd.parentElement.dataset.checked;
    if (format === "true") {
      quill.format("list", "unchecked");
    } else {
      quill.format("list", "checked");
    }
    quill.blur();
    let editor = container.getElementsByClassName("ql-editor")[0];
    dispatch("$UpdateEditorContainer", {
      id: parentElement.id,
      type: parentElement.dataset.type,
      index: parentElement.dataset.index,
      content: editor.innerHTML
    });
    parent.innerHTML = editor.innerHTML;
  },
  // 富文本内容编辑，绑定事件
  async $EditorFocusEventBind({ state, commit, dispatch }, target) {
    if (target.dataset.lock) {
      return;
    }
    console.time("editinit");
    dialogElementShow = state.dialogElementShow;
    let parentElement = target;
    if (target.classList.contains("xpp-paper-editable-box")) {
      target = target.children[0];
    }
    parentElement.classList.add("xpp-edit-active");
    parentElement.classList.add("xpp-select-active");
    let width = parentElement.clientWidth;
    let height = parentElement.clientHeight;
    parentElement.style.minHeight = height + "px";
    parentElement.style.height = null;
    // parentElement.style.minWidth = width + "px";
    // parentElement.style.width = null;
    let editToolbar = document.getElementById("xppToolbarContainer");
    editToolbar.innerHTML = state.editToolbarInnerHtml;
    // 防止拖动字体时影响操作
    target.ondragstart = event => {
      event.preventDefault();
      event.stopPropagation();
      return false;
    };
    let quill = await dispatch("$QuillInit", target);
    let editor = target.getElementsByClassName("ql-editor")[0];
    let editClickTarget = null;
    const mousedownBind = event => {
      editClickTarget = event.target;
    };
    const mouseupBind = event => {
      if (editClickTarget !== event.target) return;
      let focusEdit = target.contains(event.target);
      let focusContact = false;
      let focusToolbar = editToolbar.contains(event.target);
      if (state.dialogElementShow.xppContactListShow) {
        let contact = document.getElementById("xppContactList");
        focusContact = contact && contact.contains(event.target);
        if (!focusContact) {
          state.dialogElementShow.xppContactListShow = false;
        }
      }
      if (!focusEdit && !focusContact && !focusToolbar) {
        editorBlur();
      }
    };
    let editorBlur = () => {
      state.dialogElementShow.xppEditToolbarState = false;
      document.removeEventListener("mousedown", mousedownBind);
      document.removeEventListener("mouseup", mouseupBind);
      let parentNode = quill.container.parentElement;
      if (
        parentNode.dataset.type === "textSequence" &&
        editor.innerText.trim() === ""
      ) {
        let data = {
          action: "DELETE",
          data: {
            _id: parentNode.parentElement.id,
            type: "textSequence",
            index: parentNode.dataset.index - 0,
            textSequence: [{ id: parentNode.id }]
          },
          type: "textSequence"
        };
        commit("$DelPaperPageView", data.data);
        return dispatch("$WsSendMsg", data);
      }
      dispatch("$SendReciveuserNoti", quill.getContents());
      let width = parentElement.clientWidth + 0.5 + "px";
      let height = parentElement.clientHeight + "px";
      dispatch("$UpdateEditorContainer", {
        id: parentElement.id,
        type: parentElement.dataset.type,
        index: parentElement.dataset.index,
        content: editor.innerHTML,
        width,
        height
      });
      target.innerHTML = editor.innerHTML;
      parentElement.classList.remove("xpp-edit-active");
      parentElement.classList.remove("xpp-select-active");
      parentElement.style.width = width;
      parentElement.style.height = height;
      parentElement.style.minWidth = null;
      parentElement.style.minHeight = null;
      dispatch("$WsSendMsg", {
        action: "DISABLEDELEMENT",
        data: {
          ids: [parentElement.id],
          type: false
        }
      });
    };
    quill.keyboard.addBinding(
      {
        key: "Escape"
      },
      () => {
        if (state.dialogElementShow.xppContactListShow) {
          state.dialogElementShow.xppContactListShow = false;
        } else {
          editorBlur();
        }
      }
    );
    // editor.addEventListener("paste", event => {
    //   event.stopPropagation();
    //   event.preventDefault();
    //   let paste = event.clipboardData || window.clipboardData;
    //   let content = paste
    //     .getData("text/plain")
    //     .replace(/\</g, "&lt;")
    //     .replace(/\>/g, "&gt;")
    //     .replace(/(\r?\n)+/g, "<br>");
    //   console.log(content);
    //   document.execCommand("insertHTML", false, content);
    // });
    editor.addEventListener("input", event => {
      switch (event.data) {
        case "@":
          if (state.dialogElementShow.xppContactListShow) return;
          Vue.set(state, "xppQuillEditContainer", quill);
          let selection = quill.getSelection();
          state.xppContactsFilterInfo.selection = quill.getSelection();
          let { left, top, height } = quill.getBounds(selection.index);
          let offset = parentElement.getBoundingClientRect();
          let el_top = offset.top + top + height;
          let data = {
            top: el_top + "px",
            left: offset.left + left + "px",
            width: "308px",
            bottom: "auto"
          };
          let bodyHeight = document.documentElement.clientHeight;
          if (bodyHeight - el_top < 308) {
            data.top = "auto";
            data.bottom = bodyHeight - offset.top + "px";
          }
          commit("$UpdataVuexState", { key: "xppContactDataInfo", data });
          state.dialogElementShow.xppContactListShow = true;
          break;
        default:
          if (state.dialogElementShow.xppContactListShow) {
            let selection = state.xppContactsFilterInfo.selection;
            // 删除到@前方了
            if (quill.getSelection().index < selection.index) {
              state.dialogElementShow.xppContactListShow = false;
              return;
            }
            state.xppContactsFilterInfo.text = quill.getText(
              selection.index,
              quill.getSelection().index
            );
          }
          break;
      }
    });
    editor.onfocus = () => {
      state.dialogElementShow.xppEditToolbarState = true;
      dispatch("$WsSendMsg", {
        action: "DISABLEDELEMENT",
        data: {
          ids: [parentElement.id],
          type: true
        }
      });
    };
    quill.focus();
    quill.setSelection(quill.getLength(), 0);
    editor.style.minWidth = width - 72 + "px";
    editor.style.minHeight = height - 52 + "px";
    // document.addEventListener("click", clickBind);
    document.addEventListener("mousedown", mousedownBind);
    document.addEventListener("mouseup", mouseupBind);
    Vue.set(state, "xppQuillEditContainer", quill);
    console.timeEnd("editinit");
  },
  // 传输富文本内容
  $UpdateEditorContainer(
    { state, commit, dispatch },
    { id, type, index, width, height, content }
  ) {
    let data = state.paperViewAll[index][type][id];
    if (data.content === content) {
      // 内容未更改不继续传输数据
      return;
    }
    commit("$AddXppOperationRecord", {
      type: "update",
      data
    });
    data.content = content;
    if (width) data.style.width = width;
    if (height) data.style.height = height;
    let message = {
      action: "PUT",
      type,
      data: {
        _id: data.page_id,
        type
      }
    };
    message.data[type] = [
      {
        id: data.id
      }
    ];
    message.data[type][0][data.id] = data;
    dispatch("$WsSendMsg", message);
  },
  // 查询节点版本历史记录
  async $UpdateEditHistoryList({ state }, { page, data }) {
    if (page === 1) {
      state.editHistoryLogList = [];
    }
    let { list } = await axios.get("/mo/version/list", {
      params: {
        pageId: data.page_id,
        elementId: data.id,
        page,
        limit: 30
      }
    });
    let content = state.editHistoryLogList.length
      ? state.editHistoryLogList.slice(-1)[0].data.content
      : "<p><br></p>";
    let arr = [];
    for (let i = 0; i < list.length; i++) {
      let dd = list[i].data;
      list[i].data = dd[dd.type][0][dd[dd.type][0].id];
      list[i].data.page_index = data.page_index;
      if (content !== list[i].data.content) {
        content = list[i].data.content;
        arr.push(list[i]);
      }
    }
    if (page === 1) {
      if (arr[0] && arr[0].data.content === data.conten) {
        arr[0].active = true;
      } else {
        arr.unshift({
          active: true,
          _id: "0",
          elementId: data.id,
          updateDate: data.updataInfo.updateDate,
          data,
          pageId: data.page_id,
          createDate: data.createDate
        });
      }
      state.editHistoryLogList = arr;
    } else {
      state.editHistoryLogList = [...state.editHistoryLogList, ...arr];
    }
  },
  // 创建paper分享链接
  async $UpdataXppPaperShareDialogInfo({ state }, { paperId, operateMode }) {
    paperId = paperId || state.paperGroupId;
    let { info } = await axios.post(
      "/dis/p/share",
      formParamsFormat({
        paperId,
        operateMode
      })
    );
    state.xppPaperShareDialogInfo.loading = false;
    state.xppPaperShareDialogInfo.share = location.origin + "/s/" + info;
  },
  // 打开paper分享弹窗
  $SetXppPaperShareDialogInfo({ state, dispatch }, { id, name }) {
    Object.assign(state.xppPaperShareDialogInfo, {
      state: true,
      loading: true,
      name,
      id,
      share: ""
    });
    dispatch("$UpdataXppPaperShareDialogInfo", {
      paperId: id,
      operateMode: "2"
    });
  },
  //打开设置弹窗
  $opensetUp({ state }, { type, name, id }) {
    state.opensetUp = {
      state: true,
      type,
      name,
      id
    };
  },
  // 获取用户的容量，流量，视频时长
  async $GetXppAllowanceInfo({ state }) {
    // async $GetXppAllowanceInfo({ state, dispatch }) {
    let params = {};
    let { id, companyId } = state.xppUserInfo;
    if (companyId) {
      params.companyId = companyId;
    } else {
      params.memberId = id;
    }
    // let { info } = await axios.get("/pay/s/get", {
    //   params
    // });
    // Object.assign(state.xppAllowanceInfo, info);
    // dispatch("$VerifyXppAllowanceCredit");
  },
  // 更新/验证 剩余容量，流量，视频时长
  async $VerifyXppAllowanceCredit(
    { state, commit },
    { steamConsume = 0, storageConsume = 0 } = {}
  ) {
    let {
      originSteamData,
      steamData,
      originStorage,
      storageSpaces
    } = state.xppAllowanceInfo;
    let status = true;
    let updateaxios = null;
    let consume1 = originSteamData - steamData - steamConsume; // 剩余下载流量
    let consume2 = originStorage - storageSpaces - storageConsume; // 剩余容量空间
    if (consume1 < 0) {
      state.xppAllowanceCredit = {
        tit: "可用流量不足",
        content: "您已经使用完剩余的流量，您可单独购买流量或购买套餐进行使用。",
        href: "javascript:;"
      };
      status = false;
    }

    if (consume2 < 0) {
      state.xppAllowanceCredit = {
        tit: "储存容量不足",
        content: "您已经使用完剩余的容量，您可单独购买容量或购买套餐进行使用。",
        href: "javascript:;"
      };
      status = false;
    }

    if (!status) {
      commit("$DiaLoginFoUpDate", {
        key: "xppAllowanceCreditShow",
        value: true,
        exclusive: true
      });
    } else {
      let params = {
        id: state.xppAllowanceInfo.id
      };
      if (steamConsume) {
        params.steamData = steamConsume;
      }
      if (storageConsume) {
        params.storageSpaces = storageConsume;
      }
      // updateaxios = async () => {
      //   await axios.post("/pay/s/update", formParamsFormat(params));
      //   state.xppAllowanceInfo.storageSpaces =
      //     state.xppAllowanceInfo.storageSpaces - 0 + storageConsume;
      //   state.xppAllowanceInfo.steamData =
      //     state.xppAllowanceInfo.steamData - 0 + steamConsume;
      // };
      updateaxios = () => {
        console.log("流量记录事件已移除");
      };
    }
    return {
      status,
      updateaxios
    };
  },
  // 获取团队成员列表
  async $UpdataTeamMemberList({ state, getters }, update = false) {
    if (!getters.xppUserInfo.id) return;
    if (!getters.xppCompanyId) {
      state.xppTeamMemberAllList = [];
      localStorage.removeItem("xppTeamMemberAllList");
      state.XppContactListError.msg = "您当前没有归属团队";
      return;
    }
    let teampeople =
      JSON.parse(localStorage.getItem("xppTeamMemberAllList")) || [];
    if (teampeople.length && !update) {
      state.xppTeamMemberAllList = teampeople;
      return;
    }
    try {
      let { list } = await axios.get("/dis/rmt/list", {
        params: { companyId: getters.xppCompanyId }
      });
      if (!list.length) {
        state.XppContactListError.msg = "没有可用的团队成员";
        return;
      }
      state.userRoleInTheTeam = Vue.filter("roleDecision")(list);
      state.xppTeamMemberAllList = list;
      localStorage.setItem("xppTeamMemberAllList", JSON.stringify(list));
      return;
    } catch (err) {
      state.XppContactListError.msg = "获取团队成员列表失败";
      throw err;
    }
  },
  // 更新团队内当前在线人员列表
  async $UpdateCompanyOnlineList({ state, getters }, data) {
    if (data) {
      if (!state.companyOnlineList.find(i => i.userId === data.userId))
        state.companyOnlineList.push(data);
      return;
    }
    let { list } = await axios.get("/mo/online/getCompanyList", {
      params: {
        companyId: getters.xppCompanyId
      }
    });
    state.companyOnlineList = list;
  },
  // 获取项目/paper成员列表
  async $GetResourceMemberList(
    { state },
    { resourceId, resourceType, userRoleId = false, dup = false }
  ) {
    let { list } = await axios.get("/per/m/get", {
      params: {
        resourceId,
        resourceType
      }
    });

    // 用户类型区分
    let memberlist = [],
      partnerlist = [];
    for (let i in list) {
      let item = list[i];
      switch (item.roleId) {
        case 7:
        case 11:
          partnerlist.push(item);
          break;
        default:
          memberlist.push(item);
          break;
      }
      if (item.userId === state.xppUserInfo.id) {
        userRoleId = item.roleId;
      }
    }
    // 只获取列表
    if (dup) return { memberlist, partnerlist };
    state.xppProjectMemberInfo.userRoleId = userRoleId;
    Object.assign(state.xppResourceMemberList, { memberlist, partnerlist });
  },
  // 获取收藏列表
  async $GetCollectionList({ state, getters }, type = "2") {
    if (!getters.xppCompanyId) return;
    let { list } = await axios.get("/dis/cl/list", {
      params: {
        companyId: getters.xppCompanyId,
        sourceType: type
      }
    });
    state.xppCollectionList = list.sort((a, b) => b.updateDate - a.updateDate);
  },
  // 添加收藏或取消
  async $SetCollectionState(
    { state, getters, dispatch },
    { id, type = "2", flag = 0 }
  ) {
    await axios.post(
      "/dis/cl/collection",
      formParamsFormat({
        companyId: getters.xppCompanyId,
        sourceId: id,
        sourceType: type,
        collectionFlag: flag
      })
    );
    if (!flag) {
      let index = null;
      for (let i = 0; i < state.xppCollectionList.length; i++) {
        let item = state.xppCollectionList[i];
        if (item.sourceId === id) {
          index = i;
          break;
        }
      }
      index !== null && state.xppCollectionList.splice(index, 1);
    } else {
      dispatch("$GetCollectionList");
    }
  },
  // 当前用户所有paper及project
  async $UpdataUserProjectList({ state, getters }) {
    if (!getters.xppCompanyId) return;
    let { list } = await axios.post(
      "/dis/project/projectList?companyId=" + getters.xppCompanyId
    );
    state.xppUserProjectList = list;
    let arr = [];
    for (let i = 0; i < list.length; i++) {
      let item = list[i];
      item.paperList = item.paperList.map(dd => {
        dd.projectName = item.name;
        return dd;
      });
      arr = arr.concat(item.paperList);
    }
    arr.sort((a, b) => b.updateDate - a.updateDate);
    state.xppLatelyPaper = arr;
  },
  // 页面重新排序
  async $PaperPageSort({ state, dispatch }, sendmsg = true) {
    let sortIds = state.paperViewAll.map(item => item.page_id);
    await axios.post("/dis/p/sort", {
      parentId: state.paperGroupId,
      sortIds
    });
    if (sendmsg)
      dispatch("$WsSendMsg", {
        action: "PAGESORT",
        msgInfo: sortIds
      });
  }
};
