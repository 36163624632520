import Vue from "vue";
import store from "../store/index";

const DayArr = ["周一", "周二", "周三", "周四", "周五", "周六", "周日"];

Vue.filter("time", val => {
  let date = new Date(val - 0);
  return `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`;
});

Vue.filter("modifiedTime", (time, exact) => {
  let date = new Date(time - 0);
  let hours = date.getHours();
  let str = `${date.getMonth() + 1}月${date.getDate()}日`;
  return exact
    ? str +
        ` ${hours > 11 ? "下午" : "上午"}${
          hours > 11 ? hours - 11 : hours
        }点${date.getMinutes()}分`
    : str;
});

Vue.filter("dateToLocale", val => {
  return new Date(val - 0).toLocaleString();
});

Vue.filter("dateDivision", val => {
  let oldT = new Date(val - 0);
  let newT = new Date();
  let time = (newT - oldT) / 1000;
  let str = "";
  // 60 分钟 3600 小时 86400 天 604800 周 2592000‬ 月
  if (time < 60) {
    str = `${time < 1 ? 1 : Math.floor(time)}秒前`;
  } else if (time < 3600) {
    str = `${Math.floor(time / 60)}分钟前`;
  } else if (time < 86400) {
    str = `${Math.floor(time / 3600)}小时前`;
  } else if (time < 86400 * 30) {
    str = `${Math.floor(time / 86400)}天前`;
  } else if (time < 86400 * 7) {
    str = `上周${DayArr[oldT.getDay()]}`;
  } else {
    str = `${Math.floor(time / 86400 / 30)}月前`;
  }
  return str;
});

Vue.filter("toDateString", val => {
  let oldT = new Date(val - 0);
  let newT = new Date();
  let time = (newT - oldT) / 1000;
  let str = "";
  if (time < 60) {
    str = `${time < 1 ? 1 : Math.floor(time)}秒前`;
  } else if (time < 3600) {
    str = `${Math.floor(time / 60)}分钟前`;
  } else if (time < 86400) {
    str = `${Math.floor(time / 3600)}小时前`;
  } else if (time < 86400 * 30) {
    str = `${Math.floor(time / 86400)}天前`;
  } else {
    str = `${Math.floor(time / 86400 / 30)}月前`;
  }
  return str;
});

Vue.filter("teamInfoVail", (id, list) => {
  list = list || store.getters.xppTeamMemberList;
  try {
    for (let item of list) {
      if (id === item.userId) {
        return item;
      }
    }
  } catch (err) {
    console.log(err);
    return id;
  }
});

Vue.filter("styleUserPhoto", url => {
  return { backgroundImage: `url(${url || "/img/profile_default.png"})` };
});

Vue.filter("paperThumb", url => {
  return url ? { backgroundImage: `url(${url})` } : null;
});

// 权限过滤 - 团队
const teamroleVail = (item, admin) => {
  let role = false;
  // 超管或所有者
  switch (item.roleId) {
    case 1:
    case 13:
      role = true;
      break;
  }
  if (!admin && item.roleId === 3) role = true;
  return role;
};

// 权限过滤 - 项目/paper
// const roleVail = (item, admin) => {
//   let role = false;
//   // 超管或所有者
//   switch (item.roleId) {
//     case 4:
//     case 14:
//       role = true;
//       break;
//   }
//   if (!admin && item.roleId === 6) role = true;
//   return role;
// };

Vue.filter("roleDecision", (item, admin) => {
  switch (Object.prototype.toString.call(item)) {
    case "[object Object]":
      return teamroleVail(item, admin);
    case "[object Array]":
      let id = store.getters.xppUserInfo.id;
      for (let i = 0; i < item.length; i++) {
        let dd = item[i];
        if (dd.userId === id) {
          return teamroleVail(dd, admin);
        }
      }
      return false;
  }
});
